var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"print-notes client-notes"},[(!_vm.isLoading && _vm.templateOptions && _vm.notes.length)?_c('div',_vm._l((_vm.notes),function(note,index){return _c('div',{key:note.id,staticClass:"pl-4 pr-4 client-notes-print-section",class:index ? 'pagebreak' : ''},[_c('table',{class:index ? '' : 'avoid-break-before',staticStyle:{"width":"100%","position":"relative"}},[_c('tbody',[_c('tr',[_c('td',[_c('div',{staticClass:"mb-5"},[_c('div',{staticClass:"row m-0"},[_c('div',{staticClass:"col-6"},[_c('p',{staticClass:"mb-0"},[_vm._v(" "+_vm._s((_vm.selectedTemplates[index] || {}).label)+" ")]),(_vm.printSettings.show_name_in_report)?_c('p',{staticClass:"mb-0"},[_vm._v(" "+_vm._s(_vm.patient.name)+" ")]):_vm._e(),(_vm.printSettings.show_insurer_in_report)?_c('p',{staticClass:"mb-0"},[_vm._v(" Insurer: "+_vm._s(_vm.patient.insurer)+" ")]):_vm._e(),(_vm.printSettings.show_claim_number_in_report)?_c('p',{staticClass:"mb-0"},[_vm._v(" Claim Number: "+_vm._s(_vm.patient.claim_number)+" ")]):_vm._e()]),_c('div',{staticClass:"col-6 text-right"},[_c('p',{staticClass:"mb-0"},[_vm._v(" Date of Report: "+_vm._s(_vm._f("date")(((_vm.formInputsData || [])[index] || {})[ `sessionStartDate_${index}` ],"MMMM DD, YYYY"))+" ")]),(_vm.printSettings.show_date_of_loss_in_report)?_c('p',{staticClass:"mb-0"},[_vm._v(" Date of Loss: "+_vm._s(_vm._f("date")(_vm.patient.injured_at,"MMMM DD, YYYY"))+" ")]):_vm._e(),(_vm.printSettings.show_date_of_birth_in_report)?_c('p',{staticClass:"mb-0"},[_vm._v(" Date of Birth: "+_vm._s(_vm._f("date")((_vm.patient.user || {}).birth_date,"MMMM DD, YYYY"))+" ")]):_vm._e()])]),_c('div')]),_c('div',{staticClass:"table-content"},[_c('div',{staticClass:"header d-flex flex-column justify-content-center align-items-center"},[(
                      _vm.companyProfile.logo ||
                      (_vm.printSettings.show_second_logo_in_report &&
                        _vm.companyProfile.second_logo)
                    )?_c('img',{staticClass:"company-logo",attrs:{"src":_vm.printSettings.show_second_logo_in_report &&
                      _vm.companyProfile.second_logo
                        ? _vm.companyProfile.second_logo
                        : _vm.companyProfile.logo},on:{"load":_vm.logoLoaded}}):_vm._e(),_c('h2',{staticClass:"title mt-3 mb-0 text-center text-capitalize"},[_vm._v(" "+_vm._s(note.name)+" ")])]),(_vm.configurations && _vm.configurations[index])?_c('FormRenderer',{key:note.id,staticClass:"col-12",attrs:{"baseURL":_vm.baseUrl,"form-configuration":_vm.configurations[index]},model:{value:(_vm.formInputsData[index]),callback:function ($$v) {_vm.$set(_vm.formInputsData, index, $$v)},expression:"formInputsData[index]"}}):_vm._e(),_c('div',{staticClass:"footer mt-5"},[_c('div',{staticClass:"d-flex justify-content-between"},[_c('div',[_c('p',{staticClass:"m-0"},[_vm._v(_vm._s(_vm.companyProfile.name))]),(_vm.printSettings.show_email_in_report)?_c('p',{staticClass:"m-0"},[_vm._v(" "+_vm._s(_vm.companyProfile.email)+" ")]):_vm._e(),(_vm.printSettings.show_location_in_report)?_c('p',{staticClass:"m-0"},[_vm._v(" "+_vm._s(_vm.companyProfile.location)+" ")]):_vm._e()]),_c('div',[(_vm.printSettings.show_phone_number_in_report)?_c('p',{staticClass:"m-0"},[_vm._v(" "+_vm._s(_vm.companyProfile.phone_number)+" ")]):_vm._e(),(_vm.printSettings.show_lawyer_in_report)?_c('p',{staticClass:"m-0"},[_vm._v(" "+_vm._s(_vm.companyProfile.lawyer)+" ")]):_vm._e()])]),_c('div',{staticClass:"m-0 w-100 mt-4 text-center h5 text-danger",staticStyle:{"display":"none"}},[_vm._v(" "+_vm._s((note.data || {}).timeZone || "")+" ")])])],1)])])])])])}),0):_vm._e(),_c('loader',{staticClass:"noPrint"},[_c('b',{staticClass:"mt-4"},[_vm._v("Preparing notes to print...")])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }