<template>
  <div class="print-notes client-notes">
    <div v-if="!isLoading && templateOptions && notes.length">
      <div
        v-for="(note, index) in notes"
        :key="note.id"
        class="pl-4 pr-4 client-notes-print-section"
        :class="index ? 'pagebreak' : ''"
      >
        <table
          style="width: 100%; position: relative"
          :class="index ? '' : 'avoid-break-before'"
        >
          <tbody>
            <tr>
              <td>
                <div class="mb-5">
                  <div class="row m-0">
                    <div class="col-6">
                      <p class="mb-0">
                        {{ (selectedTemplates[index] || {}).label }}
                      </p>
                      <p class="mb-0" v-if="printSettings.show_name_in_report">
                        {{ patient.name }}
                      </p>
                      <p
                        class="mb-0"
                        v-if="printSettings.show_insurer_in_report"
                      >
                        Insurer: {{ patient.insurer }}
                      </p>
                      <p
                        class="mb-0"
                        v-if="printSettings.show_claim_number_in_report"
                      >
                        Claim Number: {{ patient.claim_number }}
                      </p>
                    </div>
                    <div class="col-6 text-right">
                      <p class="mb-0">
                        Date of Report:
                        {{
                          ((formInputsData || [])[index] || {})[
                            `sessionStartDate_${index}`
                          ] | date("MMMM DD, YYYY")
                        }}
                      </p>
                      <p
                        class="mb-0"
                        v-if="printSettings.show_date_of_loss_in_report"
                      >
                        Date of Loss:
                        {{ patient.injured_at | date("MMMM DD, YYYY") }}
                      </p>
                      <p
                        class="mb-0"
                        v-if="printSettings.show_date_of_birth_in_report"
                      >
                        Date of Birth:
                        {{
                          (patient.user || {}).birth_date
                            | date("MMMM DD, YYYY")
                        }}
                      </p>
                    </div>
                  </div>
                  <div></div>
                </div>
                <div class="table-content">
                  <div
                    class="
                      header
                      d-flex
                      flex-column
                      justify-content-center
                      align-items-center
                    "
                  >
                    <img
                      v-if="
                        companyProfile.logo ||
                        (printSettings.show_second_logo_in_report &&
                          companyProfile.second_logo)
                      "
                      class="company-logo"
                      :src="
                        printSettings.show_second_logo_in_report &&
                        companyProfile.second_logo
                          ? companyProfile.second_logo
                          : companyProfile.logo
                      "
                      @load="logoLoaded"
                    />
                    <h2 class="title mt-3 mb-0 text-center text-capitalize">
                      {{ note.name }}
                    </h2>
                  </div>
                  <FormRenderer
                    v-if="configurations && configurations[index]"
                    :key="note.id"
                    class="col-12"
                    :baseURL="baseUrl"
                    :form-configuration="configurations[index]"
                    v-model="formInputsData[index]"
                  />
                  <div class="footer mt-5">
                    <div class="d-flex justify-content-between">
                      <div>
                        <p class="m-0">{{ companyProfile.name }}</p>
                        <p
                          class="m-0"
                          v-if="printSettings.show_email_in_report"
                        >
                          {{ companyProfile.email }}
                        </p>
                        <p
                          class="m-0"
                          v-if="printSettings.show_location_in_report"
                        >
                          {{ companyProfile.location }}
                        </p>
                      </div>
                      <div>
                        <p
                          class="m-0"
                          v-if="printSettings.show_phone_number_in_report"
                        >
                          {{ companyProfile.phone_number }}
                        </p>
                        <p
                          class="m-0"
                          v-if="printSettings.show_lawyer_in_report"
                        >
                          {{ companyProfile.lawyer }}
                        </p>
                      </div>
                    </div>
                    <div
                      class="m-0 w-100 mt-4 text-center h5 text-danger"
                      style="display: none"
                    >
                      {{ (note.data || {}).timeZone || "" }}
                    </div>
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <loader class="noPrint"
      ><b class="mt-4">Preparing notes to print...</b></loader
    >
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";

export default {
  name: "PrintClinicalNotes",
  created() {
    this.$formEvent.$on("promise_start", this.promiseFired);
    this.$formEvent.$on("promise_end", this.promiseTerminated);
    window.history.replaceState("", "", "/blank");
    this.getCompanyProfile();
    this.getTemplates().then((res) => {
      if (res) {
        if (this.user) {
          this.templates = res.filter((template) => template.type != "control");
          this.templates = this.templates.map((template) => {
            let templateString = JSON.stringify(template);
            templateString = templateString
              .replace(
                /http:\/\/\{getProvidersAPI\}/g,
                `${this.baseUrl}providers-basic-info`
              )
              .replace(
                /http:\/\/\{getProvidersAPIForPatient\}/g,
                `${this.baseUrl}providers-basic-info?patient_id=${this.patientId}`
              )
              .replace(
                /http:\/\/\{getManagersAPI\}/g,
                `${this.baseUrl}managers-basic-info`
              );
            return JSON.parse(templateString);
          });
        } else {
          this.templates = [];
        }
      }
      this.getClinicalNotesByPatientId(this.patientId).then((res) => {
        if (res) {
          const notesIds = [];
          Object.keys(this.notesIds).forEach((key) =>
            notesIds.push(this.notesIds[key])
          );
          this.notes = res.filter((note) => notesIds.includes(`${note.id}`));
          this.isLoading = false;
          setTimeout(() => {
            const formData = [];
            this.notes.forEach((note) => {
              formData.push(JSON.parse(JSON.stringify(note.data)));
            });
            const formInputsDataTemp = JSON.parse(JSON.stringify(formData));
            formInputsDataTemp.forEach((inputData, index) => {
              Object.keys(inputData).forEach((key) => {
                inputData[`${key}_${index}`] = inputData[key];
                delete inputData[key];
              });
            });
            this.formInputsData = JSON.parse(
              JSON.stringify(formInputsDataTemp)
            );
            const interval = setInterval(() => {
              if (
                (this.logoReady ||
                  !this.companyProfile.logo ||
                  (this.printSettings.show_second_logo_in_report &&
                    !this.companyProfile.second_logo)) &&
                !this.promiseQueueCount
              ) {
                this.formInputsData = JSON.parse(
                  JSON.stringify(formInputsDataTemp)
                );
                clearInterval(interval);
                this.print();
              }
            }, 500);
          }, 500);
        }
      });
      this.getPatientById(this.patientId).then((res) => {
        this.patient = res || {};
      });
    });
  },
  data: () => ({
    templates: [],
    patient: {},
    notes: [],
    promiseQueueCount: 0,
    logoReady: false,
    formInputsData: [],
    isLoading: true,
  }),
  methods: {
    ...mapActions({
      getTemplates: "templates/getTemplates",
      getClinicalNotesByPatientId: "templates/getClinicalNotesByPatientId",
      getPatientById: "templates/getPatientById",
      getCompanyProfile: "settings/getCompanyProfile",
    }),
    logoLoaded: function () {
      this.logoReady = true;
    },
    promiseFired: function () {
      this.promiseQueueCount++;
    },
    promiseTerminated: function () {
      this.promiseQueueCount--;
    },
    print: function () {
      document.title = `${this.patient.name} Clinical Notes`;
      window.print();
      window.close();
    },
  },
  computed: {
    ...mapState({
      user: (state) => state.auth.user,
      companyProfile: (state) => state.settings.companyProfile,
      baseUrl: (state) => state.appConfig.api,
    }),
    notesIds: function () {
      return this.$route.query.notes;
    },
    printSettings: function () {
      const params = this.$route.query.p || {};
      Object.keys(params).forEach((key) => {
        params[key] = params[key] && params[key] == "true";
      });
      return params;
    },
    patientId: function () {
      return this.$route.params.id;
    },
    configurations: function () {
      const confs = [];
      if (this.templates && this.templates.length && this.notes.length) {
        this.notes.forEach((note) => {
          const template = this.templates.filter(
            (template) => template.id == note.form_template
          )[0];
          confs.push(JSON.parse(JSON.stringify(template.body)));
        });
      }
      confs.forEach((conf, index) => {
        Object.keys(conf.controls).forEach((key) => {
          if (conf.controls[key].name) {
            conf.controls[key].name = `${conf.controls[key].name}_${index}`;
          } else {
            conf.controls[key].name = `${key}_${index}`;
          }
          if (conf.controls[key].uniqueId) {
            conf.controls[
              key
            ].uniqueId = `${conf.controls[key].uniqueId}_${index}`;
          }
        });
      });
      return confs;
    },
    templateOptions: function () {
      if (this.templates) {
        return this.templates.map((template) => {
          return {
            value: template.id,
            label: template.title,
          };
        });
      }
      return [];
    },
    selectedTemplates: function () {
      const templates = [];
      this.notes.forEach((note) => {
        templates.push(
          this.templateOptions.filter(
            (item) => item.value == note.form_template
          )[0]
        );
      });
      return templates;
    },
  },
};
</script>

<style lang="scss">
.client-notes {
  margin: 0 auto !important;
  .min-155 {
    min-width: 0;
  }
}
.pagebreak {
  margin-top: 0;
}
.company-logo {
  position: absolute;
  left: 0;
  max-width: 30%;
  max-height: 90%;
}
.title {
  width: 40%;
  margin: 0 auto;
}
.header {
  position: relative;
  border-bottom: 1px solid lightgray;
  padding-bottom: 20px;
  min-height: 150px;
}
.footer {
  color: gray;
  border-top: 1px solid lightgray;
  padding-top: 20px;
}

.client-notes-print-section {
  padding-left: 0 !important;
  padding-right: 0 !important;
  .col-md-1 {
    select {
      min-width: 60px;
      margin-left: -8px;
    }
  }
}
@media print {
  @page {
    size: auto;
    margin: 4mm;
  }

  .patients-notes-print-main-container {
    margin-top: 0 !important;
  }

  body {
    min-width: 0 !important;
  }
  .container {
    max-width: 100%;
    min-width: 0 !important;
  }
  .patients-notes-print-main-container {
    margin-top: 24px;

    .header {
      display: none;
    }
  }
}
.vue-form-renderer .control-view,
.vue-form-renderer .control-view-wrapper {
  break-inside: avoid;
  page-break-inside: avoid;
  z-index: 9;
}
.print-notes .loader {
  left: 0;
  right: 0;
  border: 0;
  background: rgba(255, 255, 255, 0.98);
  top: 0;
  z-index: 9999;
  flex-direction: column;
  margin-left: auto;
  margin-right: auto;
}
</style>
